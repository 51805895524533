import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceType } from '../../../interface/service-type';
import { LayoutService } from '../../../service/layout.service';
import { ServiceTypeService } from '../../../service/service-type.service';
import { SnackbarService } from '../../../service/snackbar.service';

@Component({
  selector: 'app-service-type-edit',
  templateUrl: './service-type-edit.component.html',
  styleUrl: './service-type-edit.component.scss'
})
export class ServiceTypeEditComponent {

  form: FormGroup = this.formBuilder.group({
    description: ""
  });
  isUpdate: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { serviceType?: ServiceType },
    private dialogRef: MatDialogRef<ServiceTypeEditComponent>,
    private serviceTypeService: ServiceTypeService,
    private formBuilder: FormBuilder,
    private layout: LayoutService,
    private snackbar: SnackbarService
  ) {
    this.isUpdate = Boolean(this.data && this.data.serviceType);
    this.buildForm()
  }

  buildForm() {
    this.form = this.formBuilder.group({
      _id: this.isUpdate ? this.data.serviceType._id : "",
      description: [this.isUpdate ? this.data.serviceType.description : "", Validators.required]
    })
  }

  close() {
    this.dialogRef.close()
  }

  async onSubmit() {
    const newServiceType: ServiceType = this.form.getRawValue();
    try {
      this.layout.loader = true;
      if (this.isUpdate) {
        await this.serviceTypeService.update(newServiceType);
      } else {
        const id = await this.serviceTypeService.register(newServiceType);
        newServiceType['_id'] = id;
      }
      this.snackbar.success(this.isUpdate ? 'SERVICE_TYPE.UPDATED' : 'SERVICE_TYPE.ADDED');
    } catch (error) {
      this.snackbar.error(this.isUpdate ? 'SERVICE_TYPE.NOT_UPDATED' : 'SERVICE_TYPE.NOT_ADDED', null, error);
      console.error(error)
    } finally {
      this.layout.loader = false;
    }
    this.dialogRef.close(newServiceType);
  }

}
